import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { getData, addData, deleteData, editData } from '../api/data'

export const fetchCompanies = createAsyncThunk(
  'companies/fetch',
  async (token) => {
    try {
      const data = await getData('company/all', null, token)
      return data.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    } catch (e) {
      throw new Error(e.message)
    }
  }
)

export const newCompany = createAsyncThunk(
  'companies/add',
  async ({ company, token }) => {
    try {
      const data = await addData('company/new', company, token)
      return { data, projects: [] }
    } catch (err) {
      throw new Error(err.message)
    }
  }
)

export const editCompany = createAsyncThunk(
  'companies/edit',
  async ({ company, token }) => {
    try {
      const data = await editData('company/edit', company, null, token)
      return data
    } catch (err) {
      throw err
    }
  }
)

export const deleteCompany = createAsyncThunk(
  'companies/delete',
  async ({ companyId, token }) => {
    try {
      const data = await deleteData('company/delete', { _id: companyId }, token)
      return data
    } catch (err) {
      throw new Error(err.message)
    }
  }
)

const initialState = {
  loading: 'idle',
  data: [],
}

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    addCompany: (state, { payload }) => {
      return [...state, payload]
    },
  },
  extraReducers: {
    [fetchCompanies.pending]: (state) => {
      state.loading = 'pending'
    },
    [fetchCompanies.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = 'idle'
    },
    [fetchCompanies.rejected]: () => {
      console.error('fetch companies rejected!')
    },
    [newCompany.fulfilled]: (state, { payload: { data, projects } }) => {
      state.data = [...state.data, { ...data, projects }]
    },
    [newCompany.rejected]: () => {
      console.error('faild to add company!')
    },
    [editCompany.fulfilled]: (state, { payload }) => {
      const newState = [...current(state.data)]
      const companyIndex = current(state.data).findIndex(
        (company) => company._id === payload._id
      )
      newState[companyIndex] = payload
      state.data = newState
    },
    [editCompany.rejected]: () => {
      console.log('error in editing a company')
    },
    [deleteCompany.fulfilled]: (state, { payload }) => {
      const newState = [...current(state.data)]
      const companyIndex = current(state.data).findIndex(
        (company) => company._id === payload._id
      )
      console.log(companyIndex)
      newState.splice(companyIndex, 1)
      state.data = newState
    },
    [deleteCompany.rejected]: () => {
      console.error('delete project rejected!')
    },
  },
})

const { reducer, actions } = companiesSlice

export const companiesEntities = ({ companies }) => {
  const isLoading = companies.loading === 'pending'
  return { companies: companies.data, isLoading }
}

export const { addCompany } = actions

export default reducer

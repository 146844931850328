import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { addData, getData, editData, deleteData } from '../api/data'

export const fetchSettings = createAsyncThunk(
  'settings/fetch',
  async (token) => {
    try {
      const data = await getData('settings', null, token)
      return data
    } catch (e) {
      throw new Error(e.message)
    }
  }
)

export const newBrand = createAsyncThunk(
  'settings/add',
  async ({
    brand,
    selectedLogo = null,
    selectedSignature = null,
    filePriceOne = null,
    filePriceTwo = null,
    token,
  }) => {
    try {
      let formData = new FormData()

      for (const key in brand) {
        formData.append(key, brand[key])
      }

      formData.append('brandLogo', selectedLogo)
      formData.append('brandSignature', selectedSignature)
      formData.append('filePriceOne', filePriceOne)
      formData.append('filePriceTwo', filePriceTwo)

      console.log(formData)

      const data = await addData('settings/new', formData, token, {
        'Content-Type': 'multipart/form-data',
      })
      return { data }
    } catch (e) {
      throw new Error(e.message)
    }
  }
)

// export const updateBrand = createAsyncThunk(
//   'settings/edit',
//   async ({
//     brandData: {
//       brand,
//       selectedLogo,
//       selectedSignature,
//       filePriceOne,
//       filePriceTwo,
//     },
//     token,
//   }) => {
//     try {
//       let formData = new FormData();

//       for (const key in brand) {
//         formData.append(key, brand[key]);
//       }

//       formData.append('brandLogo', selectedLogo);
//       formData.append('brandSignature', selectedSignature);
//       formData.append('filePriceOne', filePriceOne);
//       formData.append('filePriceTwo', filePriceTwo);

//       const data = await editData('settings/edit', formData, null, token, {
//         'Content-Type': 'multipart/form-data',
//       });

//       return data;
//     } catch (err) {
//       throw new Error('edit brand error', err.message);
//     }
//   }
// );

export const updateBrand = createAsyncThunk(
  'settings/edit',
  async ({
    brand,
    selectedLogo,
    selectedSignature,
    filePriceOne,
    filePriceTwo,
    token,
  }) => {
    try {
      let formData = new FormData()

      for (const key in brand) {
        formData.append(key, brand[key])
      }

      formData.append('brandLogo', selectedLogo)
      formData.append('brandSignature', selectedSignature)
      formData.append('filePriceOne', filePriceOne)
      formData.append('filePriceTwo', filePriceTwo)

      console.log(formData)

      const data = await editData('settings/edit', formData, null, token, {
        'Content-Type': 'multipart/form-data',
      })

      return data
    } catch (err) {
      throw new Error('edit brand error', err.message)
    }
  }
)

export const deleteBrand = createAsyncThunk(
  'settings/delete',
  async ({ settingsId, token }) => {
    try {
      const data = await deleteData(
        'settings/delete',
        { id: settingsId },
        token
      )
      return data
    } catch (err) {
      throw new Error(err.message)
    }
  }
)

const initialState = []

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    addBrand: (state, { payload }) => {
      return [...state, payload]
    },
  },
  extraReducers: {
    [fetchSettings.fulfilled]: (state, { payload }) => {
      return payload
    },
    [fetchSettings.rejected]: () => {
      console.error('fetch settings rejected!')
    },
    [newBrand.fulfilled]: (state, { payload: { data } }) => {
      return [...state, { ...data }]
    },
    [newBrand.rejected]: () => {
      console.error('faild to add a new brand!')
    },
    [updateBrand.fulfilled]: (state, { payload }) => {
      const newState = [...current(state)]

      const brandIndex = current(state).findIndex(
        (brand) => brand._id === payload._id
      )
      newState[brandIndex] = payload
      state = newState

      return state
    },
    [updateBrand.rejected]: () => {
      console.error('edit brand settings rejected!')
    },
    [deleteBrand.fulfilled]: (state, { payload }) => {
      console.log(payload)
      const newState = [...current(state)]
      const settingsIndex = current(state).findIndex(
        (settings) => settings._id === payload._id
      )
      console.log(settingsIndex)
      newState.splice(settingsIndex, 1)

      return newState
    },
    [deleteBrand.rejected]: () => {
      console.error('delete project rejected!')
    },
  },
})

const { reducer, actions } = settingsSlice

export const { addBrand } = actions

export default reducer

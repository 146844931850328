import Cookies from 'js-cookie'

const USER_DATA = 'user-data'
const USER_REFRESH_TOKEN = 'user-refresh-token'
const USER_ACCESS_TOKEN = 'user-access-token'

export const saveUserOnCookie = (userData) => {
  localStorage.setItem(USER_DATA, JSON.stringify({ user: userData.user }))

  Cookies.set(USER_ACCESS_TOKEN, userData.accessToken, {
    expires: 1,
    sameSite: 'strict',
    // secure: true,
  })

  Cookies.set(USER_REFRESH_TOKEN, userData.refreshToken, {
    expires: 1,
    sameSite: 'strict',
    // secure: true,
  })
}

export const deleteUserOnCookie = () => {
  Cookies.remove(USER_ACCESS_TOKEN, { sameSite: 'strict' })
  Cookies.remove(USER_REFRESH_TOKEN, { sameSite: 'strict' })
  localStorage.removeItem(USER_DATA)
}

export const getUserFromCookie = () => {
  const userAccessToken = Cookies.get(USER_ACCESS_TOKEN)
  const userRefreshToken = Cookies.get(USER_REFRESH_TOKEN)

  console.log(userAccessToken, userRefreshToken)

  if (userRefreshToken === undefined) return null

  return {
    ...JSON.parse(localStorage.getItem(USER_DATA)),
    accessToken: userAccessToken,
    refreshToken: userRefreshToken,
  }
}

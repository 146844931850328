import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addData } from '../api/data'
import { saveUserOnCookie } from '../cookies/cookies'

export const registerConfirm = createAsyncThunk(
  'user/confirm',
  async ({ email, hash }) => {
    try {
      const userData = await addData('user/confirm', { email, hash })

      return userData
    } catch (err) {
      throw err
    }
  }
)

const initialState = { hashBeenConfirmed: false }

const registerConfirmSlice = createSlice({
  name: 'confirm',
  initialState,
  extraReducers: {
    [registerConfirm.fulfilled]: (state, { payload }) => {
      saveUserOnCookie(payload)

      return { hashBeenConfirmed: true }
    },
  },
})

const { reducer, actions } = registerConfirmSlice

export default reducer

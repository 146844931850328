import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addData } from '../api/data'
import { deleteUserOnCookie, saveUserOnCookie } from '../cookies/cookies'

export const login = createAsyncThunk('user/login', async ({ email }) => {
  try {
    const userData = await addData('user/admin-login', { email })

    return userData
  } catch (err) {
    throw err
  }
})

export const otp = createAsyncThunk('user/otp', async ({ email, code }) => {
  try {
    const userData = await addData('user/admin-otp', { email, code })

    return userData
  } catch (err) {
    throw err
  }
})

export const logout = createAsyncThunk('user/logout', async (token) => {
  try {
    const userData = await addData('user/logout', null, token)

    return userData
  } catch (err) {
    throw err
  }
})

const initialState = { user: null, refreshToken: '', accessToken: '' }

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginFromCookie: (state, { payload }) => {
      return payload
    },
  },
  extraReducers: {
    [logout.fulfilled]: () => {
      deleteUserOnCookie()
      return initialState
    },
    [logout.rejected]: () => {
      deleteUserOnCookie()
      return initialState
    },
    [otp.fulfilled]: (state, { payload }) => {
      saveUserOnCookie(payload)
      return payload
    },
  },
})

const { reducer, actions } = loginSlice

export const { loginFromCookie } = actions

export default reducer
